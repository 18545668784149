import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Area, IdName, Location, MyHttpApi, PortionSize, PricingGroup, PricingGroupRow, PricingGroupRowUpdateRequest, PricingGroupSpecifyRows, PricingGroupUpdateRequest, PrivilegeItem, ProductCategory, ProductSubCategory, SalesChannel, SalesRestriction } from 'utils/api';
import { getSelectedCorporation, privilegeItemFromElement, privilegeToTypeAndName, supersededText } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

export interface UIPricingGroupSpecifyRows extends IdName {
	rows: UIPricingGroupRow[];
}

interface PricingGroupSpecifyRequestTorso {
	clientId: number;
	name?: string;
}

interface PricingGroupSpecifyCancel extends PricingGroupSpecifyRequestTorso {
	rows: UIPricingGroupRow[];
}

export class UIPricingGroupRow {
	public id?: string;
	public extraPortionSize?: PortionSize;
	public extraSalesChannel?: SalesChannel;
	public extraSalesRestriction?: SalesRestriction;
	public extraLocation?: Location;
	public extraArea?: Area;

	@computedFrom("request.areaId", "request.locationId", 	"request.portionSizeId", 	"request.salesChannelId", 	"request.salesRestrictionId")
	get rowKey() {
		return "" + this.request.areaId + this.request.locationId + this.request.portionSizeId +
			this.request.salesChannelId + this.request.salesRestrictionId;
	}

	@computedFrom("request.locationId")
	get areaListDynamic() {
		if (!this.request.locationId) {
			this.request.areaId = "";
			return [];
		}
		return this.areaList.filter(a => a.locationId === this.request.locationId);
	}

	constructor(
    public request: PricingGroupRowUpdateRequest,
    public areaList: Area[],
  ) { }
}

@autoinject
export class PosPricingGroupEdit {
	private pricingGroup: PricingGroupUpdateRequest = {
		name: "",
		type: "CLIENT",
		delete: false,
		isDefault: false,
		rows: [],
	};
	private privilege?: PrivilegeItem;
	private isMaster = false;
	private typeAndName = "";
	private productCategoryList: ProductCategory[] = [];
	private productSubCategoryList: ProductSubCategory[] = [];
	private portionSizeList: PortionSize[] = [];
	private salesChannelList: SalesChannel[] = [];
	private salesRestrictionList: SalesRestriction[] = [];
	private pricingGroupList: PricingGroup[] = [];
	private extraProductCategory?: ProductCategory;
	private extraProductSubCategory?: ProductSubCategory;
	private locationList: Location[] = [];
	private areaList: Area[] = [];
	private rows: UIPricingGroupRow[] = [];
	private specifyRows: UIPricingGroupSpecifyRows[] = [];
	private clientLocationMap: { [key: number]: Location[]; } = {};
	private clientAreaMap: { [key: number]: Area[]; } = {};
	private clientPortionSizeMap: { [key: number]: PortionSize[]; } = {};
	private clientSalesChannelMap: { [key: number]: SalesChannel[]; } = {};
	private clientSalesRestrictionMap: { [key: number]: SalesRestriction[]; } = {};
	private canEdit = true;
	private canSpecify = false;
	private superseded = false;

  // * Used to specify, which client is currently being targeted
  private specifyRequest?: PricingGroupSpecifyRequestTorso = undefined;
  // * In case of specify-cancel, restore previous state
  private specifyRequestForCancel?: PricingGroupSpecifyCancel = undefined;
  // * Value from unused client list
  private specifySelectClientValue?: number;
  // * List of clients that the actor can see within the BG
  private clientList: IdName[] = [];

	constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N, private readonly notify: Notify) {
	}

	async activate(params: { id?: string, isMaster?: string; }) {
		this.isMaster = params.isMaster === "true";
		const id = params.id;
		if (!this.isMaster && !id) {
			this.privilege = getSelectedCorporation();
		} else if (this.isMaster) {
			this.privilege = undefined;
		}

		// * We need to fetch pricingGroup first, as we will anyway need privilegeId from it
		let rows: PricingGroupRow[] = [];
		let specifyRows: PricingGroupSpecifyRows[] = [];
		if (id) {
			let [pricingGroup, rowsTmp] = await Promise.all([
				this.api.pricingGroupById({ id }),
				this.api.pricingGroupListRows({ id }),
			]);
			this.pricingGroup = { ...pricingGroup, delete: !!pricingGroup.deleteTime, type: 'CLIENT', rows: [] };
			this.privilege = privilegeItemFromElement(pricingGroup);
			rows = rowsTmp.rows;
			specifyRows = rowsTmp.specifyRows.filter(x => x.rows.length);
      this.superseded = !! pricingGroup.supersededById;
		}
		let fullLocationList: Location[] = [];
		let fullAreaList: Area[] = [];
		if (this.privilege) {
			this.canEdit = await this.api.privilegeCanEdit(this.privilege);
			if (this.privilege.id && this.privilege.type === 'CLIENT') {
				const [bgr, locationList, areaList] = await Promise.all([
					this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id }),
					this.api.locationList({ id: this.privilege.id }),
					this.api.areaList({ id: this.privilege.id }),
				]);
				fullLocationList = locationList;
				fullAreaList = areaList;
				this.locationList = locationList.filter(x => !x.deleteTime);
				this.areaList = areaList.filter(x => !x.deleteTime);
				this.canEdit = bgr.clientSpecificPricingGroups == 'ALLOW';
			}
			if (this.privilege.type == 'BUSINESS_GROUP' && this.privilege.id) {
				const [clientList, bg] = await Promise.all([
					this.api.privilegeListClientsByBusinessGroupId({ businessGroupId: this.privilege.id }),
					this.api.businessGroupById({ id: this.privilege.id })
				]);
				this.clientList = clientList;
				this.canSpecify = bg.clientSpecificPricingGroups != 'RESTRICT';
				let [clientLocationMap, clientAreaMap, clientSalesChannelMap, clientPortionSizeMap, clientSalesRestrictionMap] = await Promise.all([
					this.api.locationListForBusinessGroup({ businessGroupId: this.privilege.id }),
					this.api.areaListForBusinessGroup({ businessGroupId: this.privilege.id }),
					this.api.salesChannelListForBusinessGroup({ businessGroupId: this.privilege.id }),
					this.api.portionSizeListForBusinessGroup({ businessGroupId: this.privilege.id }),
					this.api.salesRestrictionListForBusinessGroup({ businessGroupId: this.privilege.id }),
				]);
				this.specifyRows = specifyRows.map(sr => {
					let rows = sr.rows.map(r => {
						let clientId = r.clientId || 0;
						let row = this.createNewRow(r, clientAreaMap[clientId]);
						row.extraPortionSize = clientPortionSizeMap[clientId].find(x => x.id === r.portionSizeId);
						row.extraSalesChannel = clientSalesChannelMap[clientId].find(x => x.id === r.salesChannelId);
						row.extraSalesRestriction = clientSalesRestrictionMap[clientId].find(x => x.id === r.salesRestrictionId);
						row.extraSalesRestriction = clientSalesRestrictionMap[clientId].find(x => x.id === r.salesRestrictionId);
						row.extraLocation = clientLocationMap[clientId].find(x => x.id === r.locationId);
						row.extraArea = clientAreaMap[clientId].find(x => x.id === r.areaId);
						return row;
					});
					return {
						id: sr.id,
						name: sr.name,
						rows
					};
				});
				for (let clientId in clientLocationMap) {
					let locationList: Location[] = clientLocationMap[clientId] || [];
					clientLocationMap[clientId] = locationList.filter(x => !x.deleteTime);
					clientAreaMap[clientId] = clientAreaMap[clientId].filter(x => !x.deleteTime);
					clientSalesChannelMap[clientId] = clientSalesChannelMap[clientId].filter(x => !x.deleteTime && !x.supersededById);
					clientPortionSizeMap[clientId] = clientPortionSizeMap[clientId].filter(x => !x.deleteTime && !x.supersededById);
					clientSalesRestrictionMap[clientId] = clientSalesRestrictionMap[clientId].filter(x => !x.deleteTime && !x.supersededById);
				}
				this.clientLocationMap = clientLocationMap;
				this.clientAreaMap = clientAreaMap;
				this.clientSalesChannelMap = clientSalesChannelMap;
				this.clientPortionSizeMap = clientPortionSizeMap;
				this.clientSalesRestrictionMap = clientSalesRestrictionMap;
			}
		}
		const [productCategoryList, productSubCategoryList, portionSizeList, salesChannelList, salesRestrictionList, pricingGroupList] = await Promise.all([
			this.api.productCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productSubCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.portionSizeList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesChannelList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesRestrictionList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.pricingGroupList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
		]);

		this.productCategoryList = productCategoryList.filter(x => !x.deleteTime && !x.supersededById);
		this.productSubCategoryList = productSubCategoryList.filter(x => !x.deleteTime && !x.supersededById);
		this.portionSizeList = portionSizeList.filter(x => !x.deleteTime && !x.supersededById);
		this.salesChannelList = salesChannelList.filter(x => !x.deleteTime && !x.supersededById);
		this.salesRestrictionList = salesRestrictionList.filter(x => !x.deleteTime && !x.supersededById);
		this.pricingGroupList = pricingGroupList.filter(x => !x.deleteTime && !x.supersededById);

		this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);

		if (this.pricingGroup.id) {
			this.rows = rows.map(r => {
				const row = this.createNewRow(r, this.areaList);
				row.extraPortionSize = portionSizeList.find(x => x.id === r.portionSizeId);
				row.extraSalesChannel = salesChannelList.find(x => x.id === r.salesChannelId);
				row.extraSalesRestriction = salesRestrictionList.find(x => x.id === r.salesRestrictionId);
				row.extraLocation = fullLocationList.find(x => x.id === r.locationId);
				row.extraArea = fullAreaList.find(x => x.id === r.areaId);
				return row;
			});

			this.extraProductCategory = productCategoryList.find(x => x.id === this.pricingGroup.productCategoryId);
			this.extraProductSubCategory = productSubCategoryList.find(x => x.id === this.pricingGroup.productSubCategoryId);
		}
    if (this.superseded) {
      this.canEdit = this.canSpecify = false;
    }
	}

	createNewRow(request: PricingGroupRowUpdateRequest, areaList?: Area[]) {
		return new UIPricingGroupRow(request, areaList || []);
	}

  @computedFrom("disabled", "canSpecify", "superseded")
  get disabledText() {
    let text = "";
    if (this.superseded) {
      return supersededText(this.i18n, "pricingGroup");
    } else if (!this.canEdit) {
      text = this.i18n.tr('businessGroupRestriction.readOnly');
      if (this.canSpecify) {
        text += ` ${this.i18n.tr("businessGroupRestriction.canSpecify")}`;
      }
    }
    return text;
  }

	@computedFrom("privilege.type")
	get clientSpecific() {
		return this.privilege?.type === 'CLIENT';
	}

	@computedFrom("clientList", "specifyRows.length")
	get unspecifiedClientList() {
		return this.clientList.filter(c => !this.specifyRows.find(r => r.id === c.id));
	}

	/** When selecting before unseen client from select-box */
	async startSpecifyEmpty() {
		let idName = this.clientList.find(x => x.id == this.specifySelectClientValue);
		if (idName) {
			await this.startSpecify(idName);
		}
	}

	/** Open the client for modification, save the previous specify if available */
	async startSpecify(idName: IdName) {
		if (this.specifyRequest) {
			if (!(await this.saveSpecify(this.specifyRequest.clientId))) {
				return;
			}
		}
		const clientId = idName.id;
		let foundReq = this.specifyRows.find(x => x.id === clientId);
		let pgRows = foundReq?.rows || [];
		const pricingGroupsForCancelRequest = [...pgRows];
    let emptyRowRequest: PricingGroupRowUpdateRequest = {isHidden: false, portionSizeId: "", clientId,};
		let emptyRow = this.createNewRow(emptyRowRequest, this.clientAreaMap[clientId]);
		if (!pgRows.length) {
			pgRows.push(emptyRow);
		}
		let req: PricingGroupSpecifyRequestTorso = {
			clientId,
			name: idName.name,
		};
		if (!this.specifyRows.find(x => x.id === clientId)) {
			this.specifyRows.unshift({
				id: clientId,
				name: idName.name || "",
				rows: [emptyRow],
			});
		}
		this.specifyRequest = req;
		this.specifyRequestForCancel = { ...req, rows: pricingGroupsForCancelRequest.map(x => this.createNewRow({...x.request}), this.clientAreaMap[clientId]) };
		this.specifySelectClientValue = undefined;
	}

  /** Cancel the operation, restore server data that we had while loading the page originally */
  cancelSpecify() {
    let clientId = this.specifyRequest?.clientId;
    if (clientId) {
      this.specifyRequest = undefined;
      let idx = this.specifyRows.map(x => x.id).indexOf(clientId);
      // * Check if we had previous data and if did have rows
      let cr = this.specifyRequestForCancel;
      if (cr?.rows.length) {
        let originalSpecifyRow = {
          id: cr.clientId,
          name: cr.name || "",
          rows: cr.rows,
        };
        this.specifyRows.splice(idx, 1, originalSpecifyRow);
      } else {
        // * Item did not exists before, remove fully from list
        this.specifyRows = this.specifyRows.filter(x => x.id !== clientId);
      }
    }
    this.specifyRequestForCancel = undefined;
  }

	hasDuplicates(clientId?: number) {
		const rowKeys = Array.from(document.querySelectorAll(`#client${clientId ?? ''} .results tr[data-rowkey]`)).map(tr => tr.getAttribute("data-rowkey"));
		return rowKeys.length !== new Set(rowKeys).size;
	}

	/** Save the specify */
	async saveSpecify(clientId: number) {
		if(this.hasDuplicates(clientId)) {
			this.notify.info("common.duplicatedPrices", {});
			return;
		}
		let data = this.specifyRows.find(x => x.id == clientId);
		if (!data || !this.pricingGroup.id || !this.validateRows(clientId)) {
			return false;
		}
		await this.api.pricingGroupSpecify({
			elementId: this.pricingGroup.id,
			clientId: data.id,
			pricingGroupRows: data.rows.map(x => x.request),
		});
		this.specifyRequest = undefined;
		return true;
	}

	/** Just save a set of empty rows */
	async deleteSpecify() {
		const clientId = this.specifyRequest?.clientId;
		if (this.pricingGroup.id && clientId) {
			await this.api.pricingGroupSpecify({
				elementId: this.pricingGroup.id,
				clientId,
				pricingGroupRows: [],
			});
		}
		this.specifyRequest = undefined;
		this.specifyRows = this.specifyRows.filter(sr => sr.id != clientId);
	}

	validateRows(clientId: number | ""): boolean {
		const form = document.querySelector(`#client${clientId}`) as HTMLFormElement;
		form.requestSubmit();
		return form.checkValidity();
	}

	/** Check, if we have any default with these groups already defined and warn of overwriting if so */
	@computedFrom('pricingGroupList', 'pricingGroup.id', 'pricingGroup.isDefault', 'pricingGroup.productCategoryId', 'pricingGroup.productSubCategoryId')
	get defaultCrossCheckWhine() {
		let whine = false;
		if (this.pricingGroup.isDefault) {
			let privilegeType = this.privilege?.type || "CLIENT";
			let privilegeId = this.privilege?.id || 0;

			let oldDefault = this.pricingGroupList.find(x =>
				x.id !== this.pricingGroup.id &&
				x.isDefault &&
				(
					privilegeType == 'CLIENT' && privilegeId === x.clientId ||
					privilegeType == 'BUSINESS_GROUP' && privilegeId === x.businessGroupId ||
					privilegeType == 'CORPORATION' && privilegeId === x.corporationId
				) &&
				x.productCategoryId == this.pricingGroup.productCategoryId &&
				x.productSubCategoryId == this.pricingGroup.productSubCategoryId);

			if (oldDefault) {
				whine = true;
			}
		}
		return whine;
	}

	async save(deleted: boolean) {
		// prevent updating main item, if we have unfinished invalid specify process
		if (!deleted && this.specifyRequest?.clientId && !this.validateRows(this.specifyRequest?.clientId)) {
			return false;
		}

		if (this.specifyRequest) {
			if (!(await this.saveSpecify(this.specifyRequest.clientId))) {
				return;
			}
		}

		if(this.hasDuplicates()) {
			this.notify.info("common.duplicatedPrices", {});
			return;
		}

		await this.api.pricingGroupUpdate({
			...this.pricingGroup,
			rows: this.rows.map(x => x.request),
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			delete: deleted,
		});
		this.router.navigateBack();
	}

	@computedFrom("pricingGroup.productCategoryId")
	get productSubCategoryListDynamic() {
		const id = this.pricingGroup.productCategoryId;
		if (!id) {
			return [];
		}
		return this.productSubCategoryList?.filter(psc => psc.productCategoryId === id);
	}
}
