import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Location, MyHttpApi, PaymentTerminal, PaymentTerminalClient } from 'utils/api';
import { getSelectedClient } from 'utils/corporation-util';

@autoinject
export class PosPaymentTerminalList {
  private locations: { [key: string]: Location; } = {};
  private paymentTerminals: { [key: number]: PaymentTerminal } = {};
  private fieldList: FieldSpec[] = [];
  private itemList: PaymentTerminalClient[] = [];
  private clientId?: number;

  constructor(private readonly api: MyHttpApi, private readonly router: Router) {
  }

  async activate() {
    let [locationList, paymentTerminalList] = await Promise.all([
      this.api.locationListAll(),
      this.api.paymentTerminalList({ all: true }),
    ]);
    this.paymentTerminals = MyHttpApi.toHash(paymentTerminalList);
    this.locations = MyHttpApi.toHashStr(locationList)
    this.clientId = getSelectedClient();
    this.fieldList = [
      { key: "locationId", header: "app.posLocation", type: "lookup", lookupData: this.locations, lookupKey: "name" },
      { key: "paymentTerminalId", header: "payment-terminal.serialNumber", type: "lookup", lookupData: this.paymentTerminals, lookupKey: "serialNumber", },
      { key: "integrationState", header: "payment-terminal.integrationState", type: "enum", enum: "PaymentTerminalIntegrationState" },
      { key: "vivaWalletTerminalId", header: "payment-terminal.vivaWalletTerminalId", type: "text" },
      { key: "name", header: "common.name", type: "text", },
    ];
    await this.search();
  }

  async search() {
    if (!this.clientId) {
      return;
    }
    const itemList = await this.api.paymentTerminalClientList({ id: this.clientId });
    this.itemList = itemList.filter(x => !x.deleteTime);
  }

  rowCall(key: string, row?: PaymentTerminal) {
    this.router.navigateToRoute(this.editUrl, {id: row?.id, clientId: this.clientId });
  }

  @computedFrom("router.currentInstruction.config.navModel.config.name")
  get editUrl() {
    let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
    return routeName.replace(/-list$/, "-edit");
  }

  @computedFrom("clientId")
  get searchListener() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.search();
    return "";
  }
}
