import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MyHttpApi, PosPrinterType, PrinterUpdateRequest } from 'utils/api';
import { clientIdToTypeAndName, getSelectedClient, privilegeItemFromElement } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

interface UIPrinter {
  width: number;
  name: string,
  printerType: PosPrinterType;
  smallFont: number;
  bigFont: number;
  port: number;
}

@autoinject
export class PosPrinterEdit {
  private printer: PrinterUpdateRequest = {
    printerType: "ESC_POS",
    name: "",
    width: 400,
    smallFont: 400 / 24.0,
    bigFont: 400 / 16.0,
    ipAddress: "",
    type: "CLIENT",
    delete: false,
  };
  private clientId?: number;
  private typeAndName = "";

  quickSettings: UIPrinter[] = [
    {
      name: "Metapace T40",
      printerType: "ESC_POS",
      width: 512,
      smallFont: 24.0,
      bigFont: 36.0,
      port: 9100,
    },
    {
      name: "BIXOLON SRP-QE300",
      printerType: "ESC_POS",
      width: 512,
      smallFont: 24.0,
      bigFont: 36.0,
      port: 9100,
    },
    {
      name: "BIXOLON SRP-275III Dot-Matrix",
      printerType: "ESC_POS_SD",
      width: 200,
      smallFont: 9.0,
      bigFont: 13.5,
      port: 9100,
    },
  ];

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N, private readonly notify: Notify) {
  }

  setPrinter(index: number) {
    let printer = this.quickSettings[index];
    this.printer.name ||= printer.name;
    this.printer.width = printer.width;
    this.printer.smallFont = printer.smallFont;
    this.printer.bigFont = printer.bigFont;
    this.printer.port = printer.port;
    this.printer.printerType = printer.printerType;
  }

  async activate(params: { id?: string, clientId?: number; }) {
    if (params.id) {
      let printer = await this.api.printerById({ id: params.id });
      this.printer = {
        ...printer,
        delete: !!printer.deleteTime,
        type: 'CLIENT',
      };

      this.clientId = privilegeItemFromElement(printer)?.id;
    }
    if (!params.id) {
      this.clientId = getSelectedClient() || params.clientId;
    }

    this.typeAndName = await clientIdToTypeAndName(this.api, this.i18n, this.clientId);
  }

  async save(deleted: boolean) {
    if (!this.clientId) {
      return;
    }
    await this.api.printerUpdate({
      ...this.printer,
      privilegeId: this.clientId,
      type: "CLIENT",
      delete: deleted,
    });
    this.router.navigateBack();
  }

  @computedFrom("printer.printerType")
  get isTestPrinter() {
    return this.printer.printerType == "TEST";
  }

  @computedFrom("printer.printerType")
  get portVisible() {
    return ["ESC_POS", "ESC_POS_SD", "CLOUDPRNT"].includes(this.printer.printerType);
  }

  @computedFrom("printer.printerType")
  get macVisible() {
    return this.printer.printerType == "CLOUDPRNT";
  }
}
