import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MyHttpApi, PrivilegeItem, ProductCategoryUpdateRequest, StorageUnit, VatCategory } from 'utils/api';
import { getSelectedCorporation, privilegeItemFromElement, privilegeToTypeAndName, supersededText } from 'utils/corporation-util';

@autoinject
export class PosProductCategoryEdit {
  private productCategory: ProductCategoryUpdateRequest = {
    name: "",
    defaultBaseUnit: "PCS",
    vatCategoryId: 0,
    type: "CLIENT",
    delete: false,
    nonOperativeSales: false,
  };
  private privilege?: PrivilegeItem;
  private isMaster = false;
  private storageUnitList: StorageUnit[] = [];
  private extra?: StorageUnit;
  private vatCategoryList: VatCategory[] = [];
  private typeAndName = "";
  private disabled = false;
  private superseded = false;

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N) {
  }

  async activate(params: { id?: string, isMaster?: string; }) {
    this.isMaster = params.isMaster === "true";
    if (params.id) {
      let productCategory = await this.api.productCategoryById({ id: params.id });
      this.productCategory = { ...productCategory, delete: !!productCategory.deleteTime, type: 'CLIENT' };
      this.privilege = privilegeItemFromElement(productCategory);
      this.superseded = !!productCategory.supersededById;
    }
    if (!this.isMaster && !params.id) {
      this.privilege = getSelectedCorporation();
    } else if (this.isMaster) {
      this.privilege = undefined;
    }
    const [vatCategoryList, storageUnitList] = await Promise.all([
      this.api.vatCategoryList(),
      this.api.storageUnitList({ type: this.privilege?.type || 'CLIENT', id: this.privilege?.id }),
    ]);
    this.extra = storageUnitList.find(x => x.id === this.productCategory.defaultStorageUnitId);
    this.vatCategoryList = vatCategoryList;
    this.storageUnitList = storageUnitList.filter(x => !x.deleteTime && !x.supersededById);

    if (this.privilege) {
      let canEdit = await this.api.privilegeCanEdit(this.privilege);
      if (this.privilege.id && this.privilege.type === 'CLIENT') {
        const bgr = await this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id });
        canEdit = bgr.clientSpecificCategories;
      }
      this.disabled = !canEdit || this.superseded;
    }

    this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);
  }

  @computedFrom("productCategory.defaultBaseUnit")
  get storageUnitListDynamic() {
	let defaultBaseUnit = this.productCategory.defaultBaseUnit;
	let defaultStorageUnit = this.storageUnitList.find(x => x.id == this.productCategory.defaultStorageUnitId);
	if (this.productCategory.defaultStorageUnitId && defaultStorageUnit?.baseUnit !== this.productCategory.defaultBaseUnit) {
		this.productCategory.defaultStorageUnitId = undefined; // generated from next()
		this.extra = undefined;
	}

	return this.storageUnitList.filter((su: StorageUnit) => !defaultBaseUnit || su.baseUnit === defaultBaseUnit);
  }

  @computedFrom("disabled", "superseded")
  get disabledText() {
    if (this.superseded) {
      return supersededText(this.i18n, "category");
    } else if (this.disabled) {
      return this.i18n.tr('businessGroupRestriction.readOnly');
    }
    return "";
  }

  async save(deleted: boolean) {
    await this.api.productCategoryUpdate({
      ...this.productCategory,
      privilegeId: this.privilege?.id,
      type: this.privilege?.type || 'CLIENT',
      delete: deleted,
    });
    this.router.navigateBack();
  }

}
