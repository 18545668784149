import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Area, IdName, Location, MyHttpApi, PortionSize, PricingGroup, PricingGroupResponse, PrivilegeItem, ProductCategory, ProductPricingGroup, ProductPricingGroupSpecifyRow, ProductSubCategory, ProductUpdateRequest, ProductUpdateRequestPG, SalesChannel, SalesRestriction, StorageUnit, VatCategoryWithRows } from 'utils/api';
import { getPrivilege, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';
import { sameLocalDateAtMidnightUtc } from 'utils/date-util';
import { Notify } from 'utils/notify';
import { calculateCost, calculateMarginEuro, calculateMarginPercent, calculatePriceWithVat, calculateZeroVat } from 'utils/product-util';

export class UIProductPricingGroup {

	@computedFrom("request.areaId", "request.locationId", 	"request.portionSizeId", 	"request.salesChannelId", 	"request.salesRestrictionId")
	get rowKey() {
		return "" + this.request.areaId + this.request.locationId + this.request.portionSizeId +
			this.request.salesChannelId + this.request.salesRestrictionId;
	}

	setZeroVatPrice(price: number | undefined) {
		this.zeroVatPrice = calculateZeroVat(price, this.vatPercent);
		this.request.price = price;
	}

	setPrice(price: number | undefined) {
		const parsedPrice: number | undefined = (price ?? "") == "" ? undefined : parseFloat("" + price);
		if (parsedPrice == undefined || this.vatPercent == undefined) {
			return;
		}
		this.request.price = calculatePriceWithVat(parsedPrice, this.vatPercent);
	}

	@computedFrom("product.purchasePriceSu", "product.storageUnitId", "portionSize")
	get cost() {
		return calculateCost(this.storageUnits, this.product, this.portionSize, this.product.purchasePriceSu);
	}

	@computedFrom("cost", "zeroVatPrice")
	get marginEuros() {
		return calculateMarginEuro(this.cost, this.zeroVatPrice);
	}

	@computedFrom("cost", "zeroVatPrice")
	get marginPercent() {
		return calculateMarginPercent(this.cost, this.zeroVatPrice);
	}

	@computedFrom("request.portionSizeId")
	get portionSize() {
		if (this.request.portionSizeId == undefined) {
			return undefined;
		}
		return this.portionSizes[this.request.portionSizeId];
	}

	@computedFrom("request.salesChannelId")
	get salesChannel() {
		if (this.request.salesChannelId == undefined) {
			return undefined;
		}
		return this.salesChannels[this.request.salesChannelId];
	}

	@computedFrom("request.salesRestrictionId")
	get salesRestriction() {
		if (this.request.salesRestrictionId == undefined) {
			return undefined;
		}
		return this.salesRestrictions[this.request.salesRestrictionId];
	}

	@computedFrom("request.locationId")
	get location() {
		if (this.request.locationId == undefined) {
			return undefined;
		}
		return this.locations[this.request.locationId];
	}

	@computedFrom("request.areaId")
	get area() {
		if (this.request.areaId == undefined) {
			return undefined;
		}
		return this.areas[this.request.areaId];
	}

	@computedFrom("request.locationId", "areaList.length")
	get areaListDynamic() {
		if (!this.request.locationId) {
			this.request.areaId = "";
			return [];
		}
		return this.areaList.filter(a => a.locationId === this.request.locationId);
	}
	public extraPortionSize?: PortionSize;
	public extraSalesChannel?: SalesChannel;
	public extraSalesRestriction?: SalesRestriction;
	public extraLocation?: Location;
	public extraArea?: Area;
	public zeroVatPrice: number | undefined;
	// * Hox, the row values will update (e.g. vatPercent) only in save, not dynamically
	constructor(
		public product: ProductUpdateRequest,
		public request: ProductUpdateRequestPG,
		public vatPercent: number | undefined,
		public portionSizes: { [key: string]: PortionSize; },
		public salesChannels: { [key: string]: SalesChannel; },
		public salesRestrictions: { [key: string]: SalesRestriction; },
		public locations: { [key: string]: Location; },
		public areas: { [key: string]: Area; },
		public storageUnits: { [key: string]: StorageUnit; },
		public areaList: Area[],
		public manuallyAdded = false,
	) {
		this.zeroVatPrice = calculateZeroVat(this.request.price, this.vatPercent);
	}
}

export interface UIProductPricingGroupSpecifyRows extends IdName {
	rows: UIProductPricingGroup[];
}

interface ProductPricingGroupSpecifyRequestTorso {
	clientId: number;
	name?: string;
}

interface ProductPricingGroupSpecifyCancel extends ProductPricingGroupSpecifyRequestTorso {
	rows: UIProductPricingGroup[];
}

@autoinject
export class PosProductEdit {
	private product: ProductUpdateRequest = {
		abbreviation: "",
		baseUnit: "PCS",
		doNotAllowComponentsToMerge: false,
		ean: [],
		locationId: undefined,
		name: "",
		useSlavePortion: false,
		useSlavePrice: false,
		openName: false,
		openPrice: false,
		productPricingGroupList: [],
		productCategoryId: "",
		productProductList: [],
		useSalesChannel: false,
		productType: "REGULAR",
		useChosenProductOnBill: false,
		useChosenProductOnOrderList: false,
		useChosenProductOnPos: false,
		warehousedOnly: false,
		delete: false,
		type: 'CLIENT'
	};

	private activeTab = "BASIC";
	private areaList: Area[] = [];
	private areas: { [key: string]: Area; } = {};
	private canSpecify = false;
	private clientAreaMap: { [key: number]: Area[]; } = {};
	private clientLocationMap: { [key: number]: Location[]; } = {};
	private clientPortionSizeMap: { [key: number]: PortionSize[]; } = {};
	private clientSalesChannelMap: { [key: number]: SalesChannel[]; } = {};
	private clientSalesRestrictionMap: { [key: number]: SalesRestriction[]; } = {};
	private eanInput = "";
	private extraProductCategory?: ProductCategory = undefined;
	private extraProductSubCategory?: ProductSubCategory = undefined;
	private extraStorageUnit?: StorageUnit = undefined;
	private imageBlob?: string = undefined;
	private locationList: Location[] = [];
	private locationListRows: Location[] = [];
	private locations: { [key: string]: Location; } = {};
	private portionSizeList: PortionSize[] = [];
	private portionSizes: { [key: string]: PortionSize; } = {};
	private pricingGroupList: PricingGroup[] = [];
	private pricingGroupResponseList: PricingGroupResponse[] = [];
	private privilege?: PrivilegeItem;
	private productCategoryList: ProductCategory[] = [];
	private productSubCategoryList: ProductSubCategory[] = [];
	private productLocationId? = "";
	private rows: UIProductPricingGroup[] = [];
	private salesChannelList: SalesChannel[] = [];
	private salesChannels: { [key: string]: SalesChannel; } = {};
	private salesRestrictionList: SalesRestriction[] = [];
	private salesRestrictions: { [key: string]: SalesRestriction; } = {};
	private selectedDefaultPricingGroupId?: string = undefined;
	private specifyRows: UIProductPricingGroupSpecifyRows[] = [];
	private storageUnitList: StorageUnit[] = [];
	private storageUnits: { [key: string]: StorageUnit; } = {};
	private typeAndName = "";
	private vatCategoryList: VatCategoryWithRows[] = [];
	private purchasePriceSuWithVat: number | undefined;
	// * Used to specify, which client is currently being targeted
	private specifyRequest?: ProductPricingGroupSpecifyRequestTorso = undefined;
	// * In case of specify-cancel, restore previous state
	private specifyRequestForCancel?: ProductPricingGroupSpecifyCancel = undefined;
	// * Value from unused client list
	private specifySelectClientValue?: number;
	// * List of clients that the actor can see within the BG
	private clientList: IdName[] = [];

	private canEdit = false;

	private originalPurchasePrice?: number = undefined;

	constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N, private readonly notify: Notify) {
	}

	async activate(params: { id?: string; }) {
		this.privilege = getPrivilege();
		let rows: ProductPricingGroup[] = [];
		let specifyRows: ProductPricingGroupSpecifyRow[] = [];
		const id = params.id;
		if (id) {
			let [product, image, rowsTmp] = await Promise.all([this.api.productByIdExtended({ id: id }), this.api.productImageById({ id: id }), this.api.productListPrices({ id: id })]);
			this.product = {
				...product.product,
				productType: product.product.type,
				delete: !!product.product.deleteTime,
				type: this.privilege?.type || 'CLIENT',
				purchasePriceSu: product.purchasePriceSu,
				productPricingGroupList: product.productPricingGroupList,
				productProductList: [],
			};
			this.originalPurchasePrice = product.purchasePriceSu;

			rows = rowsTmp.rows;
			specifyRows = rowsTmp.specifyRows.filter(x => x.rows.length);

			this.privilege = privilegeItemFromElement(product.product);
			this.product.image = image?.imageData;
			this.imageBlob = image?.imageData && "data:" + image.imageMimeType + ";base64," + image.imageData;

			this.productLocationId = product.product.locationId;
		}
		let [productCategoryList, productSubCategoryList, storageUnitList, portionSizeList, salesChannelList, salesRestrictionList, vatCategoryList] = await Promise.all([
			this.api.productCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productSubCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.storageUnitList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.portionSizeList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesChannelList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesRestrictionList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.vatCategoryListWithRows(),
		]);

		let fullPortionSizeList: PortionSize[] = [...portionSizeList];
		let fullSalesChannelList: SalesChannel[] = [...salesChannelList];
		let fullSalesRestrictionList: SalesRestriction[] = [...salesRestrictionList];
		let fullLocationList: Location[] = [];
		let fullAreaList: Area[] = [];
		let fullStorageUnitList: StorageUnit[] = [...storageUnitList];

		this.productCategoryList = productCategoryList.filter(x => !x.deleteTime);
		this.productSubCategoryList = productSubCategoryList.filter(x => !x.deleteTime);
		this.portionSizeList = portionSizeList.filter(x => !x.deleteTime);
		this.salesChannelList = salesChannelList.filter(x => !x.deleteTime);
		this.salesRestrictionList = salesRestrictionList.filter(x => !x.deleteTime);
		this.storageUnitList = storageUnitList.filter(x => !x.deleteTime);
		this.vatCategoryList = vatCategoryList;

		this.extraProductCategory = productCategoryList.find(x => x.id === this.product.productCategoryId);
		this.extraProductSubCategory = productSubCategoryList.find(x => x.id === this.product.productSubCategoryId);
		this.extraStorageUnit = storageUnitList.find(x => x.id === this.product.storageUnitId);
		this.setPurchasePriceSuWithVat(this.product.purchasePriceSu);

		if (this.privilege) {
			this.canEdit = await this.api.privilegeCanEdit(this.privilege);
			if (this.privilege.id && this.privilege.type === 'CLIENT') {
				const [bgr, locationList, areaList] = await Promise.all([
					this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id }),
					this.api.locationList({ id: this.privilege.id }),
					this.api.areaList({ id: this.privilege.id }),
				]);
				fullLocationList = locationList;
				fullAreaList = areaList;
				this.locationListRows = locationList.filter(x => !x.deleteTime);
				this.locationList = locationList.filter(x => !x.deleteTime && (!this.productLocationId || this.productLocationId === x.id));
				this.areaList = areaList.filter(x => !x.deleteTime);
				this.canEdit = bgr.clientSpecificProducts == 'ALLOW';
			}
			if (this.privilege.type == 'BUSINESS_GROUP' && this.privilege.id) {
				const [clientList, bg] = await Promise.all([
					this.api.privilegeListClientsByBusinessGroupId({ businessGroupId: this.privilege.id }),
					this.api.businessGroupById({ id: this.privilege.id })
				]);
				this.clientList = clientList;
				this.canSpecify = bg.clientSpecificProducts !== 'RESTRICT';
				const [clientLocationMap, clientAreaMap, clientSalesChannelMap, clientPortionSizeMap, clientSalesRestrictionMap, clientStorageUnitMap] = await Promise.all([
					this.api.locationListForBusinessGroup({ businessGroupId: this.privilege.id }),
					this.api.areaListForBusinessGroup({ businessGroupId: this.privilege.id }),
					this.api.salesChannelListForBusinessGroup({ businessGroupId: this.privilege.id }),
					this.api.portionSizeListForBusinessGroup({ businessGroupId: this.privilege.id }),
					this.api.salesRestrictionListForBusinessGroup({ businessGroupId: this.privilege.id }),
					this.api.storageUnitListForBusinessGroup({ businessGroupId: this.privilege.id }),
				]);

				for (let clientId in clientLocationMap) {
					// * Make the fullAreaList to have all client areas
					const portionSizeList: PortionSize[] = clientPortionSizeMap[clientId] || [];
					const salesChannelList: SalesChannel[] = clientSalesChannelMap[clientId] || [];
					const salesRestrictionList: SalesRestriction[] = clientSalesRestrictionMap[clientId] || [];
					const locationList: Location[] = clientLocationMap[clientId] || [];
					const areaList: Area[] = clientAreaMap[clientId] || [];
					const storageUnitList: StorageUnit[] = clientStorageUnitMap[clientId] || [];

					fullPortionSizeList = [...fullPortionSizeList, ...portionSizeList];
					fullSalesChannelList = [...fullSalesChannelList, ...salesChannelList];
					fullSalesRestrictionList = [...fullSalesRestrictionList, ...salesRestrictionList];
					fullLocationList = [...fullLocationList, ...locationList];
					fullAreaList = [...fullAreaList, ...areaList];
					fullStorageUnitList = [...fullStorageUnitList, ...storageUnitList];

					clientLocationMap[clientId] = locationList.filter(x => !x.deleteTime);
					clientAreaMap[clientId] = areaList.filter(x => !x.deleteTime);
					clientPortionSizeMap[clientId] = portionSizeList.filter(x => !x.deleteTime && !x.supersededById);
					clientSalesChannelMap[clientId] = salesChannelList.filter(x => !x.deleteTime && !x.supersededById);
					clientSalesRestrictionMap[clientId] = salesRestrictionList.filter(x => !x.deleteTime && !x.supersededById);
				}
				this.clientLocationMap = clientLocationMap;
				this.clientAreaMap = clientAreaMap;
				this.clientSalesChannelMap = clientSalesChannelMap;
				this.clientPortionSizeMap = clientPortionSizeMap;
				this.clientSalesRestrictionMap = clientSalesRestrictionMap;

				this.portionSizes = MyHttpApi.toHashStr(fullPortionSizeList);
				this.salesChannels = MyHttpApi.toHashStr(fullSalesChannelList);
				this.salesRestrictions = MyHttpApi.toHashStr(fullSalesRestrictionList);
				this.storageUnits = MyHttpApi.toHashStr(fullStorageUnitList);
				this.locations = MyHttpApi.toHashStr(fullLocationList);
				this.areas = MyHttpApi.toHashStr(fullAreaList);
				this.specifyRows = specifyRows.map(sr => {
					let rows = sr.rows.map(r => {
						let row = this.createNewRow(r, r.clientId ? clientAreaMap[r.clientId] : []);
						row.extraPortionSize = this.portionSizes[r.portionSizeId];
						row.extraSalesChannel = r.salesChannelId ? this.salesChannels[r.salesChannelId] : undefined;
						row.extraSalesRestriction = r.salesRestrictionId ? this.salesRestrictions[r.salesRestrictionId] : undefined;
						row.extraLocation = r.locationId ? this.locations[r.locationId] : undefined;
						row.extraArea = r.areaId ? this.areas[r.areaId] : undefined;
						return row;
					});
					rows.sort(this.sortPricingGroupRows);
					return {
						id: sr.id,
						name: sr.name,
						rows
					};
				});
			} else {
				this.portionSizes = MyHttpApi.toHashStr(fullPortionSizeList);
				this.salesChannels = MyHttpApi.toHashStr(fullSalesChannelList);
				this.salesRestrictions = MyHttpApi.toHashStr(fullSalesRestrictionList);
				this.storageUnits = MyHttpApi.toHashStr(fullStorageUnitList);
				this.locations = MyHttpApi.toHashStr(fullLocationList);
				this.areas = MyHttpApi.toHashStr(fullAreaList);
			}

			if (this.product.id) {
				this.rows = rows.map(r => {
					const row = this.createNewRow(r, this.areaList);
					row.extraPortionSize = this.portionSizes[r.portionSizeId];
					row.extraSalesChannel = r.salesChannelId ? this.salesChannels[r.salesChannelId] : undefined;
					row.extraSalesRestriction = r.salesRestrictionId ? this.salesRestrictions[r.salesRestrictionId] : undefined;
					row.extraLocation = r.locationId ? this.locations[r.locationId] : undefined;
					row.extraArea = r.areaId ? this.areas[r.areaId] : undefined;
					return row;
				});
				this.rows.sort(this.sortPricingGroupRows);
				this.extraProductCategory = productCategoryList.find(x => x.id === this.product.productCategoryId);
				this.extraProductSubCategory = productSubCategoryList.find(x => x.id === this.product.productSubCategoryId);
			}
		}
		this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);

		let pricingGroupResponseList = await this.api.pricingGroupListWithRows({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
		this.pricingGroupResponseList = pricingGroupResponseList.filter(x => !x.pricingGroup.deleteTime && !x.pricingGroup.supersededById);
		this.pricingGroupList = this.pricingGroupResponseList.map(x => x.pricingGroup);
	}

	@computedFrom("disabled", "canSpecify")
	get disabledText() {
		let text = "";
		if (!this.canEdit) {
			text = this.i18n.tr('businessGroupRestriction.readOnly');
			if (this.canSpecify) {
				text += ` ${this.i18n.tr("businessGroupRestriction.canSpecify")}`;
			}
		}
		return text;
	}

	@computedFrom("clientList", "specifyRows.length")
	get unspecifiedClientList() {
		return this.clientList.filter(c => !this.specifyRows.find(r => r.id === c.id));
	}

	/** When selecting before unseen client from select-box */
	async startSpecifyEmpty() {
		let idName = this.clientList.find(x => x.id == this.specifySelectClientValue);
		if (idName) {
			await this.startSpecify(idName);
		}
	}

	/** Open the client for modification, save the previous specify if available */
	async startSpecify(idName: IdName) {
		if (this.specifyRequest) {
			if (!(await this.saveSpecify(this.specifyRequest.clientId))) {
				return;
			}
		}
		const clientId = idName.id;
		let foundReq = this.specifyRows.find(x => x.id === clientId);
		let ppgRows = foundReq?.rows || [];
		const productPricingGroupsForCancelRequest = [...ppgRows];
		let emptyRowRequest: ProductUpdateRequestPG = {
			clientId: clientId,
			isHidden: false,
		};
		let emptyRow = this.createNewRow(emptyRowRequest, this.clientAreaMap[clientId]);
		if (!ppgRows.length) {
			ppgRows.push(emptyRow);
		}
		let req: ProductPricingGroupSpecifyRequestTorso = {
			clientId,
			name: idName.name,
		};
		if (!this.specifyRows.find(x => x.id === clientId)) {
			this.specifyRows.unshift({
				id: clientId,
				name: idName.name || "",
				rows: [emptyRow],
			});
		}
		this.specifyRequest = req;
		this.specifyRequestForCancel = { ...req, rows: productPricingGroupsForCancelRequest.map(x => this.createNewRow({ ...x.request }, this.clientAreaMap[clientId])) };
		this.specifySelectClientValue = undefined;
	}

	/** Cancel the operation, restore server data that we had while loading the page originally */
	cancelSpecify() {
		let clientId = this.specifyRequest?.clientId;
		if (clientId) {
			this.specifyRequest = undefined;
			let idx = this.specifyRows.map(x => x.id).indexOf(clientId);
			// * Check if we had previous data and if did have rows
			let cr = this.specifyRequestForCancel;
			if (cr?.rows.length) {
				let originalSpecifyRow = {
					id: cr.clientId,
					name: cr.name || "",
					rows: cr.rows,
				};
				this.specifyRows.splice(idx, 1, originalSpecifyRow);
			} else {
				// * Item did not exists before, remove fully from list
				this.specifyRows = this.specifyRows.filter(x => x.id !== clientId);
			}
		}
		this.specifyRequestForCancel = undefined;
	}

	hasDuplicates(clientId?: number) {
		const rowKeys = Array.from(document.querySelectorAll(`#client${clientId ?? ''} .results tr[data-rowkey]`)).map(tr => tr.getAttribute("data-rowkey"));
		return rowKeys.length !== new Set(rowKeys).size;
	}

	/** Save the specify */
	async saveSpecify(clientId: number) {
		if(this.hasDuplicates(clientId)) {
			this.notify.info("common.duplicatedPrices", {});
			return;
		}
		let data = this.specifyRows.find(x => x.id == clientId);
		if (!data || !this.product.id || !this.validateRows(clientId)) {
			return false;
		}
		await this.api.productSpecify({
			elementId: this.product.id,
			clientId: data.id,
			productPricingGroupList: data.rows.map(x => x.request),
		});
		this.specifyRequest = undefined;
		return true;
	}

	/** Just save a set of empty rows */
	async deleteSpecify() {
		const clientId = this.specifyRequest?.clientId;
		if (this.product.id && clientId) {
			await this.api.productSpecify({
				elementId: this.product.id,
				clientId,
				productPricingGroupList: [],
			});
		}
		this.specifyRequest = undefined;
		this.specifyRows = this.specifyRows.filter(sr => sr.id != clientId);
	}

	createNewRow(rowRequest: ProductUpdateRequestPG, areaList: Area[]) {
		return new UIProductPricingGroup(
			this.product,
			rowRequest,
			this.vatPercent,
			this.portionSizes,
			this.salesChannels,
			this.salesRestrictions,
			this.locations,
			this.areas,
			this.storageUnits,
			areaList,
			false,
		);
	}

	@computedFrom("privilege.type")
	get clientSpecific() {
		return this.privilege?.type === 'CLIENT';
	}

	validateRows(clientId: number | ""): boolean {
		const form = document.querySelector(`#client${clientId}`) as HTMLFormElement;
		form.requestSubmit();
		return form.checkValidity();
	}

	sortPricingGroupRows(a: UIProductPricingGroup, b: UIProductPricingGroup): number {
		return (a.portionSize?.amount ?? Number.MAX_VALUE) - (b.portionSize?.amount ?? Number.MAX_VALUE)
			|| (b.location?.name ?? "").localeCompare(a.location?.name ?? "", 'fi', { sensitivity: "base" })
			|| (b.area?.name ?? "").localeCompare(a.area?.name ?? "", 'fi', { sensitivity: "base" })
			|| (a.salesChannel?.abbreviation ?? "").localeCompare(b.salesChannel?.abbreviation ?? "", 'fi', { sensitivity: "base" })
			|| (a.salesRestriction?.abbreviation ?? "").localeCompare(b.salesRestriction?.abbreviation ?? "", 'fi', { sensitivity: "base" })
			;
	}

	@computedFrom("product.productCategoryId", "extraProductCategory.id", "productCategoryList")
	get productCategory() {
		if (this.product.productCategoryId && this.product.productCategoryId === this.extraProductCategory?.id) {
			return this.extraProductCategory;
		}
		return this.productCategoryList.find(x => this.product.productCategoryId == x.id);
	}

	vatPercentByVatCategoryIdAndDate(vatCategoryList: VatCategoryWithRows[], vatCategoryId: number, date?: Date): number {
		const vc = vatCategoryList.find(vc => vc.vatCategory.id === vatCategoryId);
		if (!vc) {
			return 0;
		}
		if (!date) {
			date = sameLocalDateAtMidnightUtc(new Date());
		}
		return vc.vatCategoryRows.find(vcr => date && vcr.startDate <= date)?.vatPercent || 0;
	}

	@computedFrom("productCategory.vatCategoryId", "vatCategoryList")
	get vatPercent() {
		const productCateogry = this.productCategory;
		if (!productCateogry) {
			return undefined;
		}
		return this.vatPercentByVatCategoryIdAndDate(this.vatCategoryList, productCateogry.vatCategoryId);
	}

	@computedFrom("portionSizes", "pricingGroupResponseList", "product.productCategoryId", "productCategoryList", "salesChannels", "salesRestrictions", "selectedDefaultPricingGroupId", "sortPricingGroupRows", "storageUnits")
	get defaultProductPricingGroupObserver() {
		let effectivePricingGroup = this.pricingGroupResponseList.find(x => x.pricingGroup.id == this.selectedDefaultPricingGroupId);
		/* Clear empty rows, keep rows with price and belonging to selected/effective pricing group */
		this.rows = (this.rows.filter(pg =>
			pg.request.portionSizeId
			&& effectivePricingGroup?.rows.map(x => x.portionSizeId).includes(pg.request.portionSizeId) || pg.request.price != undefined || pg.manuallyAdded)
		);
		/* Find missing effective pricing group rows and add them to list */
		let missingPricingGroupRows = effectivePricingGroup?.rows.filter(pg =>
			!this.rows.find(ppg =>
				ppg.request.portionSizeId == pg.portionSizeId
				&& ppg.request.salesChannelId == pg.salesChannelId
				&& ppg.request.salesRestrictionId == pg.salesRestrictionId
				&& ppg.request.locationId == pg.locationId
				&& ppg.request.areaId == pg.areaId
			));

		const productCategory = this.productCategory;

		if (productCategory) {
			missingPricingGroupRows
				?.forEach(pg => {
					let request: ProductUpdateRequestPG = {
						...pg, id: undefined,
					};
					let newRow = this.createNewRow(request, this.areaList);
					this.rows.push(newRow);
				});
			this.rows.sort(this.sortPricingGroupRows);
		}

		return "";
	}

	_buildRequest(): ProductUpdateRequest {
		let obj = { ...this.product };
		obj.productPricingGroupList = this.rows.map(x => x.request);
		// Empty purchasePrice if it is same as before
		if (obj.purchasePriceSu == this.originalPurchasePrice) {
			obj.purchasePriceSu = undefined;
		}
		return obj;
	}

	async save(deleted: boolean) {
		// prevent updating main item, if we have unfinished specify process
		if (!deleted && this.specifyRequest?.clientId && !this.validateRows(this.specifyRequest?.clientId)) {
			return false;
		}


		if (this.specifyRequest) {
			if (!(await this.saveSpecify(this.specifyRequest.clientId))) {
				return;
			}
		}

		if(this.hasDuplicates()) {
			this.notify.info("common.duplicatedPrices", {});
			return;
		}

		let obj = this._buildRequest();
		if (!deleted && !obj.warehousedOnly && !obj.openPrice && !Object.values(obj.productPricingGroupList).filter(x => x.price != undefined).length) {
			this.notify.info("product.noPricingGroup", {});
			return;
		}
		await this.api.productUpdate({
			...obj,
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			delete: deleted,
		});
		this.router.navigateBack();
	}

	@computedFrom("privilege.type")
	get showLocationAndArea() {
		return this.privilege?.type === 'CLIENT';
	}

	/* Automatically fill in abbreviation. If the name is 1 char longer or shorter and shares a common root, then overwrite abbreviation. */
	@computedFrom("product.name")
	get nameObserver() {
		let name = this.product.name || "";
		let abbreviation = this.product.abbreviation || "";

		if (Math.abs(name.length - abbreviation.length) <= 1 && name.substring(0, Math.min(name.length, abbreviation.length)) === abbreviation.substring(0, Math.min(name.length, abbreviation.length))) {
			this.product.abbreviation = name.substring(0, 40); // generated from next()
		}
		return "";
	}

	selectTab(tabName: string) {
		// check that all required fields are filled in before allowing to change the tab
		if (document.querySelector("form")?.reportValidity()) {
			this.activeTab = tabName;
		}
	}

	clearImage() {
		this.product.image = undefined;
		this.imageBlob = undefined;
	}

	async productImageOnLoadHandler(event: Event) {
		const inputElement = event.target;
		const files = (<HTMLInputElement>inputElement).files;
		if (!files) {
			return;
		}
		const file = files[0];
		if (!file) {
			return;
		}
		const reader = new FileReader();
		reader.onload = async () => {
			const res = reader.result;
			if (typeof res === "string") {
				const imageData = res.split(",", 2)[1];
				let imageIsOk = await this.api.imageTest({ image: imageData });
				if (!imageIsOk) {
					this.product.image = undefined;
					this.notify.info("admin.imageTestNotOk", {});
					return;
				}
				this.imageBlob = res;
				this.product.image = imageData;
			}
		};
		reader.readAsDataURL(file);
	}

	deleteEan(idx: number) {
		this.product.ean.splice(idx, 1);
	}

	addEan() {
		if (this.eanInput.length) {
			this.product.ean.push(this.eanInput);
		}
		this.eanInput = "";
	}

	@computedFrom('product.baseUnit', 'storageUnitList.length')
	get storageUnitListDynamic() {
		let baseUnit = this.product.baseUnit;
		return this.storageUnitList.filter(su => !baseUnit || su.baseUnit === baseUnit);
	}

	@computedFrom('product.productCategoryId', 'productSubCategoryList.length')
	get productSubCategoryListDynamic() {
		return this.productSubCategoryList.filter(pc => this.product.productCategoryId != undefined && pc.productCategoryId === this.product.productCategoryId);
	}

	@computedFrom("privilege.type", "product.id", "productLocationId", "locationList.length")
	get canModifyLocation() {
		return !!(this.privilege?.type === 'CLIENT' && ((!this.product.id && this.locationList.length > 1) || this.productLocationId));
	}

	@computedFrom("pricingGroupList")
	get defaultPricingGroupList(): PricingGroup[] {
		return this.pricingGroupList.filter(x => x.isDefault);
	}

	areaListDynamic(locationId: string) {
		return this.areaList.filter(x => x.locationId === locationId);
	}

	@computedFrom("product.baseUnit")
	get baseUnitObserver() {
		let storageUnit = this.storageUnitList.find(su => su.baseUnit === this.product.baseUnit);
		if (!storageUnit) {
			this.product.storageUnitId = undefined;
		}
		return "";
	}

	setPurchasePriceSuWithVat(price: number | undefined) {
		// this.product.purchasePriceSu may be string, convert to numeric/undefined
		const purchasePriceSu: number | undefined = (price ?? "") == "" ? undefined : parseFloat("" + price);
		if (purchasePriceSu == undefined || this.productCategory == undefined || this.vatPercent == undefined) {
			return;
		}
		this.product.purchasePriceSu = purchasePriceSu;
		this.purchasePriceSuWithVat = calculatePriceWithVat(purchasePriceSu, this.vatPercent);
	}

	setPurchasePriceSu(price: number | undefined) {
		if (price == undefined || this.productCategory == undefined || this.vatPercent == undefined) {
			this.product.purchasePriceSu = undefined;
			return;
		}
		let zeroVatPrice = 100 * price / (100 + this.vatPercent);
		zeroVatPrice = Math.round(zeroVatPrice * 1000) / 1000;
		this.product.purchasePriceSu = zeroVatPrice;
	}

	onCategoryChanged() {
		let productCategory = this.productCategory;
		const productSubCategory = this.productSubCategoryList.find(x => x.id === this.product.productSubCategoryId);
		if (this.product.productSubCategoryId && productSubCategory?.productCategoryId !== productCategory?.id) {
			this.product.productSubCategoryId = undefined; // generated from next()
			this.extraProductSubCategory = undefined;
		}
		if (productCategory) {
			this.product.baseUnit = productSubCategory?.defaultBaseUnit || productCategory.defaultBaseUnit;
			this.product.storageUnitId = productSubCategory?.defaultStorageUnitId || productCategory.defaultStorageUnitId;
			//this.refreshSelectedDefaultPricingGroupId();
		}

		// Default to most specific pricing group
		const subcategorySpecificPG = this.defaultPricingGroupList.find(pg => pg.productCategoryId == this.product.productCategoryId && pg.productSubCategoryId == this.product.productSubCategoryId);
		const mainCategorySpecificPG = this.defaultPricingGroupList.find(pg => pg.productCategoryId == this.product.productCategoryId && pg.productSubCategoryId == undefined);
		const genericPG = this.defaultPricingGroupList.find(pg => pg.productCategoryId == undefined && pg.productSubCategoryId == undefined);

		const defaultPricingGroup = subcategorySpecificPG ?? mainCategorySpecificPG ?? genericPG;
		this.selectedDefaultPricingGroupId = defaultPricingGroup?.id;
		return "";
	}
}

