import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, Client, Location, MyHttpApi, PrivilegeItem, Product, ProductCategory, ProductSubCategory, ProductType } from 'utils/api';
import { getAllAccessLevelsViaClient, getSelectedCorporation } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

interface UIProduct extends Product {
  deleted: boolean;
}

@autoinject
export class PosProductList {
  private fieldList: FieldSpec[] = [];
  private readonly defaults: { [key: string]: string; } = { "deleted": " " };
  private itemList: UIProduct[] = [];
  private privilege?: PrivilegeItem;
  private productType?: ProductType = undefined;
  private clients: { [key: number]: Client; } = {};
  private businessGroups: { [key: number]: BusinessGroup; } = {};
  private productCategories: { [key: string]: ProductCategory; } = {};
  private productSubCategories: { [key: string]: ProductSubCategory; } = {};
  private locations: { [key: string]: Location; } = {};

  constructor(private readonly notify: Notify, private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N) {
  }

  async activate(params: { isMaster: string; productType: ProductType; }) {
    this.productType = params.productType as ProductType | undefined;
    let [{ clients, businessGroups, corporations }, productCategoryList, productSubCategoryList, locationList ] = await Promise.all([
        getAllAccessLevelsViaClient(this.api),
        this.api.productCategoryListAll(),
        this.api.productSubCategoryListAll(),
        this.api.locationListAll(),
    ]);
    this.clients = clients;
    this.businessGroups = businessGroups;
    this.productCategories = MyHttpApi.toHashStr(productCategoryList);
    this.productSubCategories = MyHttpApi.toHashStr(productSubCategoryList);
    this.locations = MyHttpApi.toHashStr(locationList);

    this.privilege = getSelectedCorporation();
    this.fieldList = [
    { key: "name", header: "common.name", type: "text", },
    { key: "abbreviation", header: "common.abbreviation", type: "text" },
    { key: "productCategoryId", header: "app.posProductCategory", type: "lookup", lookupData: this.productCategories, lookupKey: "name", },
    { key: "productSubCategoryId", header: "app.posProductSubCategory", type: "lookup", lookupData: this.productSubCategories, lookupKey: "name", },
    { key: "locationId", header: "app.posLocation", type: "lookup", lookupData: this.locations, lookupKey: "name", },
    { key: "deleted", header: "common.deleted", type: "boolean", },
    { key: "corporationId", header: "corporation.corporation", type: "lookup", lookupData: corporations, lookupKey: "name", },
    { key: "businessGroupId", header: "businessGroup.businessGroup", type: "lookup", lookupData: businessGroups, lookupKey: "name", },
    { key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
    ];
  }

  async rowCall(key: string, row?: Product) {
    if (this.privilege?.type === 'CLIENT' && !row?.id) {
      let itemType: "REGULAR" | "ALIAS" | "RECIPE" = row?.type ?? this.productType ?? "REGULAR";
      const bgId = this.clients[this.privilege.id].businessGroupId || 0;
      const rule = {
        "REGULAR": this.businessGroups[bgId].clientSpecificProducts === "ALLOW",
        "ALIAS": this.businessGroups[bgId].clientSpecificAliases,
        "RECIPE": this.businessGroups[bgId].clientSpecificRecipes,
      }[itemType];

      if (bgId && !rule) {
        this.notify.info("businessGroupRestriction.notAllowed", { element: this.i18n.tr(`businessGroupRestriction.${itemType}`) });
        return;
      }
    }
    this.router.navigateToRoute(this.editUrl, { id: row?.id });
  }

  async search() {
    if (!this.privilege) {
      return;
    }
    const itemList = await this.api.productList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
    this.itemList = itemList.filter(x => !x.supersededById && x.type === this.productType).map(x => ({...x, deleted: !!x.deleteTime}));
  }

  @computedFrom("productType")
  get productTypeTranslation() {
    return this.i18n.tr(`ProductType.${(this.productType || "REGULAR")}`).toLowerCase();
  }

  @computedFrom("router.currentInstruction.config.navModel.config.name")
  get editUrl() {
    let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
    return routeName.replace(/-list$/, "-edit");
  }

  @computedFrom("privilege.id")
  get searchListener() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.search();
    return "";
  }
}
